import React from 'react'

export default function Footer() {
  return (
    <div>
      <section id="footer">
        <div class="container text-center" > 
          <p>All the best !<i class="fas fa-smile"></i></p>
        </div>
      </section>
    </div>
  )
}
